











































































































































































































































































































































































































































































































































import {Component, Vue} from 'vue-property-decorator';
import NorthStarService from "../../NorthStarService"
import {NorthStar, Objective as Objective} from "../../typescript/types";
import CreateObjectiveTrigger from "../../components/objectives/CreateObjectiveTrigger.vue";
import { endOfWeek } from "date-fns";
import ConfettiCanon from "../../components/general/ConfettiCanon.vue";
import {PublicBoardTemplatePaginator} from "@/typescript/types";

//@ts-ignore
import image1 from '../../assets/images/AddGoalBg1.png';
//@ts-ignore
import image2 from '../../assets/images/goalbg3.webp';
//@ts-ignore
import image3 from '../../assets/images/AddGoalBg2.jpg';
//@ts-ignore
import image4 from '../../assets/images/cgb1.webp';
//@ts-ignore
import image5 from '../../assets/images/cbg2.webp';

@Component({
  components: {ConfettiCanon, CreateObjectiveTrigger},
})
export default class OnboardingAddGoal extends Vue {
  createNorthStarInput: string | null = null;
  submitted: boolean = false;
  northstarsAddedCount: number = 0;
  service: NorthStarService | null = null;
  addedNorthStars: NorthStar[] = [];
  addedObjectives: Objective[] = [];
  addActivitiesActive: boolean = false;
  lastAddedNorthstar: NorthStar | null = null;
  publicBoardTemplatesPageCount: number = 1;
  PublicBoardTemplatesData: PublicBoardTemplatePaginator | null = null;
  highlightButton: boolean = false;
  highlightCustomizeButton: boolean = false;
  selectedCategory: number | null = null;
  selectedGoal: number | null = null;
  selectedImage: string = image1;
  hideInspirationPreview: boolean = false;
  manualAddLoading: boolean = false;
  selectedColor: string = '#773f75';
  playScreenAnimation: boolean = false;
  dynamicTooltip: string = 'nada';
  dynamicSMARTTooltip: string = '';
  fireConfetti: boolean = false;

  colors: Array<string> = [
    '#773f75',
    '#64024C',
    '#820263',
    '#bd5fb4',
    '#5B507A',
    '#2E294E',
    '#374ea9',
    '#4a677d',
    '#4e6068',
    '#66737a',
    '#1F4730',
    '#316863',
    '#2e825f',
    '#546356',
    '#642B2C',
    '#654C4F',
    '#776015',
    '#7b7343',
  ];

  inspiration = {
    categories: [
      { 
        name: "Work", 
        id: 1, 
        img: require('../../assets/images/cgb1.webp'),
        bgcolor: '#F9EEFC',
        description: 'Achieve professional success through targeted actions and skill enhancement',
        goals: [
          {
            id: 101,
            title: "Build a Website", 
            description: "Over the next 3 months, complete the creation of a website. Start with wireframing, then design the layout, develop the site, and finally, launch it. Set weekly milestones to maintain steady progress.",
            objectives: [
              { id: 10101, objective: "Draft initial wireframe" },
              { id: 10102, objective: "Design website layout" },
              { id: 10103, objective: "Develop core website functionalities" },
              { id: 10104, objective: "Launch the final version" }
            ]
          },
          {
            id: 102,
            title: "Improve Customer Service",
            description: "Enhance customer satisfaction over the next 6 months. Gather feedback through surveys, implement new service protocols, train your team, and conduct quarterly policy reviews.",
            objectives: [
              { id: 10201, objective: "Conduct customer feedback surveys" },
              { id: 10202, objective: "Implement new service protocols" },
              { id: 10203, objective: "Train team members on new procedures" },
              { id: 10204, objective: "Quarterly policy reviews" }
            ]
          },
          {
            id: 103,
            title: "Increase Sales by 20%",
            description: "Boost sales by 20% in the next 4 months. Develop a comprehensive marketing plan, launch social media campaigns, monitor sales metrics weekly, and adjust strategies accordingly.",
            objectives: [
              { id: 10301, objective: "Create a detailed marketing plan" },
              { id: 10302, objective: "Execute social media campaigns" },
              { id: 10303, objective: "Monitor sales metrics weekly" },
              { id: 10304, objective: "Adjust strategies based on performance" }
            ]
          },
          {
            id: 104,
            title: "Expand Product Line",
            description: "Introduce 3 new products in the next 6 months. Conduct market research, design the products, test them with focus groups, and prepare for a successful launch.",
            objectives: [
              { id: 10401, objective: "Conduct market research" },
              { id: 10402, objective: "Design new products" },
              { id: 10403, objective: "Test products with focus groups" },
              { id: 10404, objective: "Launch new products" }
            ]
          },
          {
            id: 105,
            title: "Improve Project Management",
            description: "Implement new project management tools in the next 3 months. Research the best tools, train your team, integrate them into your workflow, and evaluate their effectiveness.",
            objectives: [
              { id: 10501, objective: "Research project management tools" },
              { id: 10502, objective: "Train team on new tools" },
              { id: 10503, objective: "Integrate tools into workflow" },
              { id: 10504, objective: "Evaluate tool effectiveness" }
            ]
          },
          {
            id: 106,
            title: "Boost Team Morale",
            description: "Within 6 months, increase team engagement and satisfaction. Conduct satisfaction surveys, organize team-building activities, implement feedback mechanisms, and reward high performers.",
            objectives: [
              { id: 10601, objective: "Conduct employee satisfaction surveys" },
              { id: 10602, objective: "Organize team-building activities" },
              { id: 10603, objective: "Implement feedback mechanisms" },
              { id: 10604, objective: "Reward high-performing employees" }
            ]
          }
        ]
      },
      { 
        name: "Learn", 
        id: 2, 
        
        bgcolor: '#EEF7FB',
        description: 'Expand your knowledge and skills with educational objectives',
        goals: [
          {
            id: 201,
            title: "Learn a New Language",
            description: "Become proficient in a new language within 6 months. Complete a beginner's course, practice daily with language apps, join a language exchange group, and achieve conversational fluency.",
            objectives: [
              { id: 20101, objective: "Finish a beginner's course" },
              { id: 20102, objective: "Daily practice using language apps" },
              { id: 20103, objective: "Join a language exchange group" },
              { id: 20104, objective: "Reach conversational fluency" }
            ]
          },
          {
            id: 202,
            title: "Master a Programming Language",
            description: "Become proficient in a programming language within 6 months. Complete online courses, work on projects, contribute to open source, and earn a certification.",
            objectives: [
              { id: 20201, objective: "Complete online programming courses" },
              { id: 20202, objective: "Build practical projects" },
              { id: 20203, objective: "Contribute to open-source projects" },
              { id: 20204, objective: "Earn a programming certification" }
            ]
          },
          {
            id: 203,
            title: "Read 6 Books",
            description: "Read six books over the next 6 months. Create a reading list, allocate daily reading time, join a book club, and write summaries of each book.",
            objectives: [
              { id: 20301, objective: "Create a reading list" },
              { id: 20302, objective: "Allocate daily reading time" },
              { id: 20303, objective: "Join a book club" },
              { id: 20304, objective: "Write book summaries" }
            ]
          },
          {
            id: 204,
            title: "Learn to Play an Instrument",
            description: "Learn a musical instrument within 6 months. Choose an instrument, find a tutor or online course, practice daily, and perform a piece of music.",
            objectives: [
              { id: 20401, objective: "Select an instrument" },
              { id: 20402, objective: "Find a tutor or online course" },
              { id: 20403, objective: "Daily practice sessions" },
              { id: 20404, objective: "Perform a musical piece" }
            ]
          },
          {
            id: 205,
            title: "Take an Online Course",
            description: "Complete an online course in 3 months. Select a course topic, enroll, complete weekly assignments, and pass the final assessment.",
            objectives: [
              { id: 20501, objective: "Select a course topic" },
              { id: 20502, objective: "Enroll in the course" },
              { id: 20503, objective: "Complete weekly assignments" },
              { id: 20504, objective: "Pass the final assessment" }
            ]
          },
          {
            id: 206,
            title: "Develop a New Skill",
            description: "Acquire a new skill within 6 months. Identify the skill, find relevant resources or courses, practice regularly, and track your progress.",
            objectives: [
              { id: 20601, objective: "Identify the skill to learn" },
              { id: 20602, objective: "Find learning resources or courses" },
              { id: 20603, objective: "Regular practice sessions" },
              { id: 20604, objective: "Track progress and improvement" }
            ]
          }
        ]
      },
      { 
        name: "Health", 
        id: 3, 
        img: require('../../assets/images/goalbg3.webp'),
        bgcolor: '#FEF9EF',
        description: 'Prioritize your well-being with goals focused on physical and mental health',
        goals: [
          {
            id: 301,
            title: "Exercise Regularly",
            description: "Commit to exercising regularly over the next 6 months. Create a workout plan, join a gym or class, track your progress, and achieve specific fitness milestones.",
            objectives: [
              { id: 30101, objective: "Develop a workout plan" },
              { id: 30102, objective: "Join a gym or fitness class" },
              { id: 30103, objective: "Track progress using a fitness app" },
              { id: 30104, objective: "Achieve a fitness milestone" }
            ]
          },
          {
            id: 302,
            title: "Eat Healthier",
            description: "Adopt healthier eating habits within 3 months. Plan your weekly meals, incorporate more fruits and vegetables, reduce sugar intake, and track your daily nutrition.",
            objectives: [
              { id: 30201, objective: "Plan weekly meals" },
              { id: 30202, objective: "Increase fruit and vegetable intake" },
              { id: 30203, objective: "Reduce sugar consumption" },
              { id: 30204, objective: "Track daily nutrition" }
            ]
          },
          {
            id: 303,
            title: "Improve Mental Health",
            description: "Enhance your mental health within 6 months. Practice mindfulness or meditation, seek therapy, maintain a gratitude journal, and engage in relaxing hobbies.",
            objectives: [
              { id: 30301, objective: "Practice mindfulness or meditation" },
              { id: 30302, objective: "Seek therapy or counseling" },
              { id: 30303, objective: "Keep a gratitude journal" },
              { id: 30304, objective: "Engage in relaxing hobbies" }
            ]
          },
          {
            id: 304,
            title: "Improve Sleep Quality",
            description: "Improve your sleep quality within 3 months. Establish a bedtime routine, limit screen time before bed, create a comfortable sleep environment, and track your sleep patterns.",
            objectives: [
              { id: 30401, objective: "Set a consistent bedtime routine" },
              { id: 30402, objective: "Reduce screen time before bed" },
              { id: 30403, objective: "Optimize sleep environment" },
              { id: 30404, objective: "Track sleep patterns" }
            ]
          },
          {
            id: 305,
            title: "Reduce Stress",
            description: "Manage stress more effectively within 3 months. Identify stress triggers, practice relaxation techniques, engage in regular physical activity, and seek support when needed.",
            objectives: [
              { id: 30501, objective: "Identify and document stress triggers" },
              { id: 30502, objective: "Practice relaxation techniques daily" },
              { id: 30503, objective: "Exercise regularly" },
              { id: 30504, objective: "Seek support from friends or professionals" }
            ]
          },
          {
            id: 306,
            title: "Quit Smoking",
            description: "Quit smoking within the next 6 months. Set a quit date, identify triggers, use nicotine replacement therapy, and join a support group for assistance.",
            objectives: [
              { id: 30601, objective: "Set a definitive quit date" },
              { id: 30602, objective: "Identify smoking triggers" },
              { id: 30603, objective: "Use nicotine replacement therapy" },
              { id: 30604, objective: "Join a support group" }
            ]
          }
        ]
      },
      {
        name: "Money",
        id: 4,
        
        bgcolor: '#E8FEEB',
        description: 'Enhance your financial stability with smart saving, investing, and budgeting strategies',
        goals: [
          {
            id: 401,
            title: "Save for an Emergency Fund",
            description: "Build an emergency fund covering six months of expenses within a year. Set a savings goal, create a budget, automate transfers, and monitor your progress monthly.",
            objectives: [
              { id: 40101, objective: "Set a savings goal" },
              { id: 40102, objective: "Create a monthly budget" },
              { id: 40103, objective: "Automate savings transfers" },
              { id: 40104, objective: "Monitor progress monthly" }
            ]
          },
      
          {
            id: 402,
            title: "Invest in Stocks",
            description: "Grow your wealth by investing in the stock market over the next 6 months. Research options, open a brokerage account, invest monthly, and review your portfolio quarterly.",
            objectives: [
              { id: 40201, objective: "Research stock investment options" },
              { id: 40202, objective: "Open a brokerage account" },
              { id: 40203, objective: "Invest a set amount monthly" },
              { id: 40204, objective: "Review and adjust portfolio quarterly" }
            ]
          },
          {
            id: 403,
            title: "Pay off Debt",
            description: "Eliminate outstanding debt within a year. List all debts, create a repayment plan, prioritize high-interest debt, and track progress monthly.",
            objectives: [
              { id: 40301, objective: "List all debts and their interest rates" },
              { id: 40302, objective: "Create a detailed repayment plan" },
              { id: 40303, objective: "Prioritize repayment of high-interest debts" },
              { id: 40304, objective: "Track debt reduction progress monthly" }
            ]
          },
          {
            id: 404,
            title: "Save for Retirement",
            description: "Increase retirement savings over the next year. Determine a savings goal, contribute to a retirement account, maximize employer matching, and review the plan annually.",
            objectives: [
              { id: 40401, objective: "Determine a retirement savings goal" },
              { id: 40402, objective: "Contribute regularly to a retirement account" },
              { id: 40403, objective: "Maximize employer matching contributions" },
              { id: 40404, objective: "Annually review and adjust retirement plan" }
            ]
          },
          {
            id: 405,
            title: "Create a Financial Plan",
            description: "Develop a comprehensive financial plan in 6 months. Assess your current financial situation, set financial goals, create a budget, and monitor the plan regularly.",
            objectives: [
              { id: 40501, objective: "Assess current financial situation" },
              { id: 40502, objective: "Set short and long-term financial goals" },
              { id: 40503, objective: "Develop a budget and savings plan" },
              { id: 40504, objective: "Monitor and adjust the plan regularly" }
            ]
          },
          {
            id: 406,
            title: "Increase Income",
            description: "Boost your income within 6 months. Identify side job opportunities, enhance employability skills, seek promotions or higher-paying jobs, and invest in income-generating assets.",
            objectives: [
              { id: 40601, objective: "Identify opportunities for side jobs" },
              { id: 40602, objective: "Enhance skills to increase employability" },
              { id: 40603, objective: "Seek promotions or higher-paying jobs" },
              { id: 40604, objective: "Invest in income-generating assets" }
            ]
          }
        ]
      }
    ]
  };




  placeHolderColors: Array<string> = [
    '#754373',
    '#4a677d',
    '#7b7343',
  ];

  get me() {
    return this.$store.state.me;
  }

  get workspace() {
    return this.$store.state.workspace;
  }


  get addedNorthstarsCount() {
    return this.addedNorthStars.length;
  }

  

  get isFromAd() {
    return this.$store.state.user_from_ad
  }

  get endDate() {
    return endOfWeek(new Date(), {weekStartsOn: 1});
  }

  get board() {
    return this.$store.state.board
  }

 

  handleCategoryClick(id: number, img: string) {
    this.selectedCategory = id;
    this.selectedImage = img;
    this.hideInspirationPreview = true;
  }

  hideInspiration() {
    this.playScreenAnimation = true;
    if (this.$refs.createNsInput) {  
      // @ts-ignore
      this.$refs.createNsInput.focus();
    }
    setTimeout(() => {
      this.playScreenAnimation = false;
      this.hideInspirationPreview = !this.hideInspirationPreview;
    }, 800);
   
  }

  setInputFocus() {
    if (this.$refs.createNsInput) {  
      // @ts-ignore
      this.$refs.createNsInput.focus();
    }
  }

  updateTooltip(description: string) {
    this.dynamicTooltip = description;
  }

  updateSMARTTooltip(text: string) {
    this.dynamicSMARTTooltip = text;
  }

  showInspiration() {
    this.hideInspirationPreview = false;
    if (this.$refs.createNsInput) {  
      // @ts-ignore
      this.$refs.createNsInput.focus();
    }
  }

  handleGoalClick(id: number, img: string) {
    this.selectedGoal = id;
    this.selectedImage = img;
  }


  bounceInput() {
    this.highlightButton = true;
    setTimeout(() => this.highlightButton = false, 1000);

    this.$nextTick(function () {
      if(this.$refs.createActivityInput) {    
        // @ts-ignore
        this.$refs.createActivityInput.focus();
      } else if (this.$refs.createNsInput) {  
        // @ts-ignore
        this.$refs.createNsInput.focus();
      }
    });
  }

  bounceCustomizeButton() {
    this.highlightCustomizeButton = true;
    setTimeout(() => this.highlightCustomizeButton = false, 1000);
  }

  createOrAddNorthStar(manually: boolean | null): void {
    this.hideInspiration();
    if(manually) {
      this.$gtag.event('pmb_new_app_activation_northstar_added_manually');
    }
    this.$gtag.event('pmb_new_app_activation_ncorthstar_added');
    this.submitted = true;
    let startDate = new Date();
    
    let finalColor = this.selectedColor;

    this.manualAddLoading = true;
    if (!finalColor && this.colors.length > 0) {
      finalColor = this.colors[Math.floor(Math.random() * this.colors.length)];
    }

    if (this.$store.state.board) {
        this.service?.createNorthStar(this.$store.state.workspace, this.$store.state.board, this.createNorthStarInput!, this.endDate, startDate, finalColor).then((result: any) => {
          this.$store.commit('set_newly_created_northstar_count', (this.$store.state.newlyCreatedNorthstarsCountInWorkspace == null) ? 1 : this.$store.state.newlyCreatedNorthstarsCountInWorkspace + 1)
          this.$store.commit('set_newly_created_northstar_in_board', result.data.createNorthStar.id);
          this.$gtag.event('pembio_activation_create_north_star');
          this.$events.fire('new_northstar_created', result.data.createNorthStar)
          this.lastAddedNorthstar = result.data.createNorthStar;
          this.addedNorthStars.push(result.data.createNorthStar);
        })
    }
    this.$nextTick(function () {
      // @ts-ignore
      this.$refs.createNsInput.focus();
    });
    this.createNorthStarInput = null;
    this.$emit('northstar-added', true);
    this.closeModal();
  }
  

  mounted() {
    this.service = new NorthStarService(this.$apollo);
    
    
    this.$nextTick(() => {
      if (this.$refs.createNsInput) {
        //@ts-ignore
        this.$refs.createNsInput.focus();
      }
    });
    
  }
 
  handleDoneButton() {
    // this.fireConfetti = true;
    setTimeout(() => {
      this.closeModal();
    }, 1000);
  }

  closeModal() {
    // @ts-ignore
    this.$parent.close() 
  }



  jumpToActivitiesStep() {
    this.addActivitiesActive = true;
    this.$nextTick(function () {
      // @ts-ignore
      this.$refs.createActivityInput.focus();
    });
  }



  

}
