






























import {Component, Prop, Vue} from 'vue-property-decorator';
import {getDayOfYear, getDaysInYear} from "date-fns";


@Component({
  components: {},
})
export default class PageNotFound extends Vue {

  months: Array<string> = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  @Prop({required: true, default: null})
  items!: Array<any>

  calculateStartOffset(doy: Date) {
    let yearDayCount = getDaysInYear(new Date);
    let startDay = 0;
    if(doy) {
      startDay = getDayOfYear(new Date(doy))
    }

    return (startDay / yearDayCount) * 100;
  }

  calculateWidth(start: Date, due: Date | null) {
    let yearDayCount = getDaysInYear(new Date);
    let startDay = 0;
    let endDay = 0;
    if(start) {
      startDay = getDayOfYear(new Date(start));
    } else {
      startDay = 0;
    }
    if(due) {
      endDay = getDayOfYear(new Date(due!));
    } else {
      endDay = yearDayCount;
    }
    let widthInDays = endDay - startDay;

    return (widthInDays / yearDayCount) * 100;
  }

}
