




















































































import {Component, Vue} from 'vue-property-decorator';
import Planner from "@/components/layout/Planner.vue";
import {eachMonthOfInterval, isWithinInterval, differenceInDays, differenceInMonths, addMonths, endOfMonth} from "date-fns";
import VueDraggableResizable from 'vue-draggable-resizable';


@Component({
  components: {Planner, VueDraggableResizable},
})
export default class NorthstarsGantt extends Vue {
  intervalStart: any = null;
  intervalEnd: any = null;
  periodicity: Array<string> = [
    'weekly', 'monthly', 'quarterly', 'yearly'
  ]
  selectedPeriodicity: string = 'monthly';

  //gridMovementScale: number = 180;
  gridMovementScale: number = 180;

  currentlySelecting: boolean = false;
  selectedStartCell: any = null;
  selectedEndCell: any = null;

  daysInInterval: any | null = null;

  ganttItems: Array<{ text: string; start: Date, end: Date }> = [
    { text: 'Publish blog post', start: new Date(2024, 0, 1), end: new Date(2024, 1, 0)},
    { text: 'dodo', start: new Date(2024, 1, 1), end: new Date(2024, 3, 0)},
  ];

  get items() {
    return 4;
  }

  onDragStop(data: any) {
    let position = data;
    let offset =  position / this.gridMovementScale;

    let newStartDate = addMonths(new Date(this.intervalStart), offset);

    console.log('offset months: ', offset, 'new date', newStartDate);
  }

  onResize(handle: any, x: any, y: any, width: any, height: any) {
    console.log(handle, x, y, width, height);
    console.log('resizing to width: ', y);

    let dueDate = (handle + y) / this.gridMovementScale;

    console.log('new due date: ', dueDate);
  }

  laneWidth(start: any, end: any) {
    let diffMonths = differenceInMonths(end, start);
    let width = (diffMonths * this.gridMovementScale) + this.gridMovementScale;
    return width;
  }

  get ganttListItem() {
    return {
      name: 'Alex',
      startDate: 'January 1, 2024',
      endDate: 'March 15, 2024'
    };
  }

  get gridCells() {
    let result = eachMonthOfInterval({
      start: this.intervalStart,
      end: this.intervalEnd
    })
    return result;
  }

  isWithinSelectionRange(date: any) {
    return isWithinInterval(new Date(date), {
      start: new Date(this.selectedStartCell),
      end: new Date(this.selectedEndCell)
    })
  }

  clearCellSelection() {
    this.selectedStartCell = null;
    this.selectedEndCell = null;
  }

  startCellSelection(item: any) {
    this.currentlySelecting = true;
    this.selectedStartCell = item;
    this.selectedEndCell = endOfMonth(item);
  }

  moveCellSelection(item: any) {
    if(this.currentlySelecting) {
      this.selectedEndCell = endOfMonth(item);
    }
  }
  endCellSelection() {
    this.ganttItems.push({ text: 'New goal', start: new Date(this.selectedStartCell), end: new Date(this.selectedEndCell) });
    this.currentlySelecting = false;
    this.clearCellSelection();
  }

  calculateIntervals() {
    this.daysInInterval = differenceInDays(
      this.intervalEnd,
      this.intervalStart
    )
  }

  calculateStartOffset(date: any) {

    let diffMonths = differenceInMonths(
      date,
      this.intervalStart
    )
    return this.gridMovementScale * diffMonths;
  }

  mounted() {
    this.intervalStart = new Date(2024, 0, 1)
    this.intervalEnd = new Date(2024, 5, 1)

    this.calculateIntervals();
  }
}
