













































































import {Component, Vue} from 'vue-property-decorator';
import WeeklyEngagement from "@/components/workspace/statistics/WeeklyEngagement.vue";

@Component({
  components: {WeeklyEngagement},
})
export default class Planner extends Vue {
  expanded: boolean = false;
  masonry: boolean = true;
}
